import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { Images } from "../constant";
import { catchError, mergeMap } from "rxjs/operators";
import { CRUDService, BaseService, CommonUtility, DateUtility } from "projects/common/src/public-api";
import { APIConstant } from "../constant";
import { Employee, EmployeeBasicInfo, APIPagedResponse, EmployeeLeaveRequest, TeacherSubject, Birthday, EmployeeField } from "models";

@Injectable({
    providedIn: "root",
})
export class EmployeeService extends CRUDService<Employee> {
    constructor(protected baseService: BaseService) {
        super(baseService, APIConstant.employee + "/");
    }

    allEmployee(params: any): Observable<APIPagedResponse<Employee>> {
        const query = CommonUtility.convertObjectToParams(params);
        return this.baseService.get(`${APIConstant.allEmployee}?${query}`);
    }

    filter(data: any): Observable<EmployeeBasicInfo[]> {
        const params = CommonUtility.convertObjectToParams(data);
        return this.baseService.get<EmployeeBasicInfo[]>(`${APIConstant.searchEmployee}?${params}`);
    }

    search(seach: string): Observable<EmployeeBasicInfo[]> {
        const params = CommonUtility.convertObjectToParams({
            searchString: seach,
        });
        return this.baseService.get<EmployeeBasicInfo[]>(`${APIConstant.searchEmployee}?${params}`);
    }

    getLeavesOfEmployee(data: any): Observable<APIPagedResponse<EmployeeLeaveRequest>> {
        const query = CommonUtility.convertObjectToParams(data);
        return this.baseService.get(`${APIConstant.leaveRequestsOfEmployee}?${query}`);
    }
    basicInfo(employeeProfileId: number): Observable<EmployeeBasicInfo> {
        return this.baseService.get<EmployeeBasicInfo>(`${APIConstant.employee}/basicInfo?employeeProfileId=` + employeeProfileId);
    }
    basicPostInfo(data:any): Observable<EmployeeBasicInfo> {
        return this.baseService.post(`${APIConstant.postInfo}?searchEmployeeProfileId=${data}`,null)
    }

    getSubjectsMappedToTeacher(employeeProfileId: number, academicYear?: string): Observable<TeacherSubject[]> {
        const query = CommonUtility.convertObjectToParams({
            employeeProfileId: employeeProfileId,
            academicYear: academicYear,
        });
        return this.baseService.get(`${APIConstant.subjectMappedToTeacher}?${query}`);
    }

    getImage(employeeProfileId: number): Observable<string> {
        const queryParams = CommonUtility.convertObjectToParams({
            fileName: employeeProfileId,
            httpMethod: "GET",
        });
        return this.baseService
            .getWithResponseType<string>(`${APIConstant.employee}/${employeeProfileId}/image?${queryParams}`, "text")
            .pipe(catchError(() => of(Images.user)));
    }

    uploadImage(employeeProfileId: number, file: File): Observable<any> {
        const queryParams = CommonUtility.convertObjectToParams({
            employeeProfileId: employeeProfileId,
            contentType: file.type,
        });
        return this.baseService.put(`${APIConstant.employee}/${employeeProfileId}/image?${queryParams}`, null).pipe(
            mergeMap((response: any) => {
                return this.baseService.put(response.URL, file);
            })
        );
    }

    getContactDetailsReport(params: { [key: string]: any }): Observable<APIPagedResponse<EmployeeBasicInfo>> {
        const queryParams = CommonUtility.convertObjectToParams(params);
        return this.baseService.get<APIPagedResponse<EmployeeBasicInfo>>(`${APIConstant.employeeContactDetails}?${queryParams}`);
    }

    getEmployeeReport(params: any, body: any): Observable<APIPagedResponse<Employee>> {
        const query = CommonUtility.convertObjectToParams(params);
        return this.baseService.post(`${APIConstant.employeeReport}?${query}`, body);
    }

    getEmployeeBirthdaysReport(data: any): Observable<Birthday[]> {
        const queryParams = CommonUtility.convertObjectToParams({
            ...data
        });
        return this.baseService.get<Birthday[]>(`${APIConstant.birthdayReport}?${queryParams}`);
    }

    getEmployeeMissingFields(): Observable<EmployeeField[]> {
        return this.baseService.get<EmployeeField[]>(`${APIConstant.employeeMissingInfo}fields`);
    }

    getEmployeeMissingData(data: object): Observable<any[]> {
        const queryParams = CommonUtility.convertObjectToParams({
            ...data
        });
        return this.baseService.get<string[]>(`${APIConstant.employeeMissingInfo}data?${queryParams}`);
    }

    updateEmployeeMissingData(params: any, data: any) {
        const query = CommonUtility.convertObjectToParams(params);
        return this.baseService.put(`${APIConstant.employeeMissingInfo}data?${query}`, data);
    }

    importEmployeeData(data: any) {
        return this.baseService.post(`${APIConstant.employeeImport}`, data);
    }

    getAllEmployees() {
        return this.baseService.get(`${APIConstant.all}`);
    }

    getLoginEmployee() {
        return this.baseService.get(`${APIConstant.loginEmployee}`);
    }

    studentSearchFilter(params: any, data: any): Observable<any> {
        const query = CommonUtility.convertObjectToParams(params);
        return this.baseService.post(`${APIConstant.advanceSearchEmployee}?${query}`, data);
    }

    getCurrentAcademicYear() {
        return this.baseService.get(`${APIConstant.currentAcademicYear}?`);
    }

    validateMobile(mobile: any): Observable<any> {
        return this.baseService.get<any>(`${APIConstant.validateMobile}?mobile=${mobile}`);
    }

    validateEmail(email: any): Observable<any> {
        return this.baseService.get<any>(`${APIConstant.validateEmail}?email=${email}`);
    }

    getClassroomId(data:any){
        // return this.baseService.get<any>(`${APIConstant.getClass}?academicYear=${data}`)
        return this.baseService.get<any>(`https://spectrum.myakshar.com/spectrum-api/admissions/enquiries/allEnquiries/byAcademicYear?academicYear=${data}`)
    }

    getEnquiryData(academicYear:any,admittedClass:any){
        return this.baseService.get<any>(`${APIConstant.getEnquiry}?academicYear=${academicYear}&admittedClass=${admittedClass}`)
    }
}
